import {WidgetRendererProps} from '@app/dashboard/biolink/biolink-editor/content/widgets/types/widget-renderer-props';
import {TextWidget} from '@app/dashboard/biolink/biolink-editor/content/widgets/text-widget/text-widget-dialog';

export function TextWidgetRenderer({
  widget,
  variant,
}: WidgetRendererProps<TextWidget>) {
  if (!widget.config.title && !widget.config.description) return null;

  if (variant === 'editor') {
    return (
      <div className="text-sm text-muted whitespace-nowrap overflow-hidden">
        <div>{widget.config.title}</div>
        <div className="overflow-hidden overflow-ellipsis">
          {widget.config.description}
        </div>
      </div>
    );
  }

  return (
    <div className={``}>
      <div className={`${widget.config?.title_style_font_align ?? 'text-center'} ${widget.config?.title_style_font_size ?? 'text-md'} ${widget.config?.title_style_font_wight ?? 'font-bold'} mb-3`}>{widget.config.title}</div>
      <div className={`${widget.config?.desc_style_font_align ?? 'text-center'} ${widget.config?.desc_style_font_size ?? 'text-sm'} ${widget.config?.desc_style_font_wight ?? 'font-normal'}`}>{widget.config.description}</div>
    </div>
  );
}
